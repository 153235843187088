import React, { Component } from 'react';
import axios from 'axios';
import Loading from './Loading'
import { Button, Modal } from 'react-bootstrap'




class Products extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            updateShow: false,
            productId: "1",
            productName: "",
            productCategory: "",
            productStatus: "",
            productCategorySelector: [],
            productData: [],

            modifyId: "",
            modifyProductName: "",
            modifyProductCategory: "",
            modifyProductStatus: ""

        }
    }

    componentDidMount = async () => {
        this.getProductCategory()
        this.getProductData();
    }

    handelModel = () => {
        this.setState({ show: !this.state.show })

    }

    handleProductName = async e => {
        const productName = e.target.value;
        await this.setState({ productName: productName })
    }
    handleProductStatus = async e => {
        const productStatus = e.target.value;
        await this.setState({ productStatus: productStatus })
        console.log(this.state.productStatus)

    }

    getProductCategory = () => {
        axios.get('https://uslaundryapi.herokuapp.com/categoryGet')
            .then(res => {
                let catData = res.data.filter((item) => {
                    return String(item.status).match("Active")
                })
                this.setState({ productCategorySelector: catData });

            })
    }

    incrementProductId = async () => {
        let currentId = parseInt(this.state.productId);
        let nextId = currentId + 1;
        await this.setState({ productId: nextId })
    }


    postProduct = async () => {
        let { productId, productName, productCategory, productStatus } = this.state;
        const productDetail = {
            id: productId,
            productName: productName,
            categoryName: productCategory,
            status: productStatus
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(productDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/productPost', body, config);
            this.incrementProductId();

            this.state.productData.push(productDetail)

        } catch (err) {
            console.log(err);
        }

    }

    handleProductCategory = async e => {
        const productCat = e.target.value;
        await this.setState({ productCategory: productCat })
        console.log(this.state.productCategory)
    }


    handelProduct = async () => {
        await this.postProduct();
        this.setState({ show: false, productName: "", productCategory: "" })

    }

    getProductData = () => {
        this.setState({ loading: true })

        axios.get('https://uslaundryapi.herokuapp.com/productGet')
            .then(res => {

                this.setState({ productData: res.data, loading: false });
                if (res.data.length > 0) {
                    const newId = res.data.map((item) => {
                        return Number(item.id)
                    })
                    let id = Math.max(...newId);
                    let finalId = id + 1;
                    this.setState({ productId: finalId })
                }

            })
    }

    getTableData = () => {

        return (
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Product</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>



                    {this.state.productData.map((data, index) => {
                        const { id, productName, categoryName, status } = data
                        return (
                            <tr key={index}>
                                <td>{id}</td>
                                <td>{productName}</td>
                                <td>{categoryName}</td>
                                <td ><span className={`${status === "Active" ? "badge badge-success" : "badge badge-warning"}`} style={{ padding: "7px" }}>{status}</span></td>
                                <td>
                                    <button type="button" onClick={() => this.showModification(data)} className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>
                                    <button type="button" onClick={() => this.handleDelete(data)} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table >
        )
    }

    handleDelete = (product) => {
        const products = this.state.productData.filter(s => s._id !== product._id);
        this.setState({ productData: products })

        const productRecord = product._id;
        const productId = {
            id: productRecord
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify(productId);
        axios.post('https://uslaundryapi.herokuapp.com/productDelete', body, config)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

    }

    showModification = async (data) => {
        this.setState({ updateShow: true, modifyId: data.id, modifyProductName: data.productName, modifyProductCategory: data.categoryName, modifyProductStatus: data.status })

    }

    handleModifyProductName = async e => {
        const modifyProductName = e.target.value;
        await this.setState({ modifyProductName: modifyProductName })

    }

    handleModifyProductCategory = async e => {
        const modifyProductCategory = e.target.value;
        await this.setState({ modifyProductCategory: modifyProductCategory })

    }

    handleModifyProductStatus = async e => {
        const modifyProductStatus = e.target.value;
        await this.setState({ modifyProductStatus: modifyProductStatus })
    }

    updateProduct = async () => {
        let { modifyId, modifyProductName, modifyProductCategory, modifyProductStatus } = this.state;
        const updateProductDetail = {
            id: modifyId,
            productName: modifyProductName,
            categoryName: modifyProductCategory,
            status: modifyProductStatus
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(updateProductDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/productUpdate', body, config);

        } catch (err) {
            console.log(err);
        }
    }

    handelModifyModel = async () => {
        await this.setState({ updateShow: false })
    }

    handelModifyProduct = async () => {
        await this.updateProduct()
        await this.setState({ updateShow: false })
        await this.getProductData();
        this.getTableData();

    }


    render() {

        return (

            <React.Fragment>



                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Product List</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>

                                        <button type="button" className="btn btn-success btn-md" onClick={() => this.handelModel()}><i className="fas fa-plus mr-1"></i>New</button>

                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 500 }}>
                                {this.state.loading ? <Loading /> : this.getTableData()}


                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>






                {/* new product */}
                <Modal show={this.state.show}>
                    <Modal.Header ><h3>Add New Product</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.productId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="productName">Product Name</label>
                            <input type="text" className="form-control" id="productName" value={this.state.productName} onChange={this.handleProductName} placeholder="Enter Product Name" />
                            <br />
                            <label htmlFor="produceCategory">Select Category:</label>
                            <select className="form-control" id="produceCategory" onChange={this.handleProductCategory}>

                                <option value="">Select Category</option>

                                {this.state.productCategorySelector.map((data, index) => {

                                    return (
                                        <option key={index} value={data.categoryName}>{data.categoryName}</option>

                                    )
                                })}

                            </select>
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handleProductStatus}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="productStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="productStatus" value="Disable" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelProduct()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* update product */}
                <Modal show={this.state.updateShow}>
                    <Modal.Header ><h3>Update Product</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.modifyId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="productName">Product Name</label>
                            <input type="text" className="form-control" id="productName" value={this.state.modifyProductName} onChange={this.handleModifyProductName} placeholder="Enter Product Name" />
                            <br />
                            <label htmlFor="produceCategory">Select Category:</label>
                            <select className="form-control" id="produceCategory" onChange={this.handleModifyProductCategory}>

                                <option value="">Select Category</option>

                                {this.state.productCategorySelector.map((data, index) => {

                                    return (
                                        <option key={index} value={data.categoryName}>{data.categoryName}</option>

                                    )
                                })}

                            </select>
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handleModifyProductStatus}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="productStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="productStatus" value="Disable" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModifyModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelModifyProduct()}>Update</Button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment >

        )

    }
}

export default Products;











