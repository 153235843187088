import React from 'react'
import { Link } from "react-router-dom";

export default function Menu() {
    return (

        <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
            {/* Brand Logo */}
            <a href="index3.html" className="brand-link">
                <center><img src="dist/img/logo.png" alt="Uber Logo" width="150" /></center>

            </a>

            <div className="sidebar" id="mySidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="dist/img/user2-160x160.png" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <Link to="/" className="d-block">Administrator</Link>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                        <li className="nav-item has-treeview menu-open">

                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">
                                        <i className="fas fa-desktop nav-icon" />
                                        <p>Dashboard</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/orders" className="nav-link">
                                        <i className="fas fa-sort-amount-up-alt nav-icon" />
                                        <p>Orders</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/services" className="nav-link">
                                        <i className="fab fa-servicestack nav-icon" />
                                        <p>Services</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/categories" className="nav-link">
                                        <i className="fas fa-cubes nav-icon" />
                                        <p>Categories</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/products" className="nav-link">
                                        <i className="fas fa-box-open nav-icon" />
                                        <p>Products</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/management" className="nav-link">
                                        <i className="fas fa-tasks nav-icon" />
                                        <p>Fare Management</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/customers" className="nav-link">
                                        <i className="fas fa-users nav-icon" />
                                        <p>Customers</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deliveryBoys" className="nav-link">
                                        <i className="fas fa-user-alt nav-icon" />
                                        <p>Delivery Boys</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/promoCodes" className="nav-link">
                                        <i className="fas fa-code nav-icon" />
                                        <p>Promo Codes</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/labels" className="nav-link">
                                        <i className="fas fa-tags nav-icon" />
                                        <p>Labels</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/faqs" className="nav-link">
                                        <i className="fas fa-question nav-icon" />
                                        <p>Faqs</p>
                                    </Link>
                                </li>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                            </ul>
                        </li>
                    </ul>
                </nav>

            </div>

        </aside>
    )
}
