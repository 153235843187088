import React, { Component } from 'react';
import axios from 'axios';
import Loading from './Loading'
import { Button, Modal } from 'react-bootstrap'




class DeliveryBoys extends Component {
    constructor() {
        super()
        this.state = {

            show: false,
            updateShow: false,
            boyId: "1",
            deliveryBoyName: "",
            deliveryBoyNumber: "",
            deliveryBoyEmail: "",
            deliveryBoyStatus: "",
            loading: false,
            boysData: [],

            modBoyId: "",
            modDeliveryBoyName: "",
            modDeliveryBoyNumber: "",
            modDeliveryBoyEmail: "",
            modDeliveryBoyStatus: ""


        }
    }

    componentDidMount = () => {
        this.getDeliveryBoy(true);
    }

    handelModel = () => {
        this.setState({ show: !this.state.show })

    }

    incrementBoyId = async () => {
        let currentId = parseInt(this.state.boyId);
        let nextId = currentId + 1;
        await this.setState({ boyId: nextId })
    }

    postBoy = async () => {
        let { boyId, deliveryBoyName, deliveryBoyNumber, deliveryBoyEmail, deliveryBoyStatus } = this.state;
        const boyDetail = {
            id: boyId,
            boyName: deliveryBoyName,
            mobile: deliveryBoyNumber,
            email: deliveryBoyEmail,
            status: deliveryBoyStatus

        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(boyDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/deliveryBoyPost', body, config);
            this.incrementBoyId();



        } catch (err) {
            console.log(err);
        }

    }

    handelInputChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }

    handelModInputChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }

    handelBoyPost = async () => {
        await this.postBoy();
        await this.setState({
            deliveryBoyName: "",
            deliveryBoyNumber: "",
            deliveryBoyEmail: "",
            deliveryBoyStatus: "",
            show: false
        })
        await this.getDeliveryBoy(false);
        this.getTableData();

    }


    getDeliveryBoy = (loadValue) => {
        this.setState({ loading: loadValue })

        axios.get('https://uslaundryapi.herokuapp.com/deliveryBoyGet')
            .then(res => {

                this.setState({ boysData: res.data, loading: false });
                if (res.data.length > 0) {
                    const newId = res.data.map((item) => {
                        return Number(item.id)
                    })
                    let id = Math.max(...newId);
                    let finalId = id + 1;
                    this.setState({ boyId: finalId })
                }

            })
    }

    getTableData = () => {

        return (
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Delivery Boy Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>



                    {this.state.boysData.map((data, index) => {
                        const { id, boyName, mobile, email, status } = data
                        return (
                            <tr key={index}>
                                <td>{id}</td>
                                <td>{boyName}</td>
                                <td>{mobile}</td>
                                <td>{email}</td>
                                <td ><span className={`${status === "Active" ? "badge badge-success" : "badge badge-warning"}`} style={{ padding: "7px" }}>{status}</span></td>
                                <td>
                                    <button type="button" onClick={() => this.showModification(data)} className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>
                                    <button type="button" onClick={() => this.handleDelete(data)} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table >
        )
    }

    handleDelete = async (data) => {
        const boys = this.state.boysData.filter(s => s._id !== data._id);
        this.setState({ boysData: boys })

        const boysRecord = data._id;
        const boyId = {
            id: boysRecord
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify(boyId);
        axios.post('https://uslaundryapi.herokuapp.com/deliveryBoyDelete', body, config)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

    }

    showModification = async (data) => {

        await this.setState({
            modBoyId: data.id,
            modDeliveryBoyName: data.boyName,
            modDeliveryBoyNumber: data.mobile,
            modDeliveryBoyEmail: data.email,
            modDeliveryBoyStatus: data.status,
            updateShow: true
        })
        console.log(this.state.modBoyId)
        console.log(this.state.modDeliveryBoyName)
        console.log(this.state.modDeliveryBoyNumber)
        console.log(this.state.modDeliveryBoyEmail)
        console.log(this.state.modDeliveryBoyStatus)

    }

    handelModModel = () => {
        this.setState({ updateShow: false })
    }

    handelBoyUpdate = async () => {
        await this.updateBoy();
        await this.getDeliveryBoy(false);
        this.setState({ updateShow: false })

    }

    updateBoy = async () => {
        let { modBoyId,
            modDeliveryBoyName,
            modDeliveryBoyNumber,
            modDeliveryBoyEmail,
            modDeliveryBoyStatus } = this.state;
        const updateBoyDetail = {
            id: modBoyId,
            boyName: modDeliveryBoyName,
            mobile: modDeliveryBoyNumber,
            email: modDeliveryBoyEmail,
            status: modDeliveryBoyStatus
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(updateBoyDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/deliveryBoyUpdate', body, config);

        } catch (err) {
            console.log(err);
        }
    }
    render() {

        return (

            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Delivery Boys</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>

                                        <button type="button" className="btn btn-success btn-md" onClick={() => this.handelModel()}><i className="fas fa-plus mr-1"></i>New</button>

                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 500 }}>
                                {this.state.loading ? <Loading /> : this.getTableData()}


                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>


                {/* new boys model */}
                <Modal show={this.state.show}>
                    <Modal.Header ><h3>Delivery Boys</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.boyId}</h5>
                            </div>
                        </div>

                        <form>

                            <label htmlFor="deliveryBoy">Delivery Boy Name</label>
                            <input type="text" className="form-control" id="deliveryBoy" name="deliveryBoyName" onChange={this.handelInputChange} placeholder="Enter Delivery Boy Name" />
                            <br />
                            <label htmlFor="deliveryBoyMobile">Delivery Boy Mobile Number</label>
                            <input type="number" className="form-control" id="deliveryBoyMobile" name="deliveryBoyNumber" onChange={this.handelInputChange} placeholder="Enter Delivery Boy Mobile Number" />
                            <br />
                            <label htmlFor="deliveryBoyEmail">Delivery Boy Mobile Email</label>
                            <input type="email" className="form-control" id="deliveryBoyEmail" name="deliveryBoyEmail" onChange={this.handelInputChange} placeholder="Enter Delivery Boy Email" />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handelInputChange}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="deliveryBoyStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="deliveryBoyStatus" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelBoyPost()}>Save</Button>
                    </Modal.Footer>
                </Modal>





                {/* update boys model */}
                <Modal show={this.state.updateShow}>
                    <Modal.Header ><h3> Update Delivery Boys</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.modBoyId}</h5>
                            </div>
                        </div>

                        <form>

                            <label htmlFor="deliveryBoy">Delivery Boy Name</label>
                            <input type="text" className="form-control" id="deliveryBoy" name="modDeliveryBoyName" onChange={this.handelModInputChange} placeholder="Enter Delivery Boy Name" />
                            <br />
                            <label htmlFor="deliveryBoyMobile">Delivery Boy Mobile Number</label>
                            <input type="number" className="form-control" id="deliveryBoyMobile" name="modDeliveryBoyNumber" onChange={this.handelModInputChange} placeholder="Enter Delivery Boy Mobile Number" />
                            <br />
                            <label htmlFor="deliveryBoyEmail">Delivery Boy Mobile Email</label>
                            <input type="email" className="form-control" id="deliveryBoyEmail" name="modDeliveryBoyEmail" onChange={this.handelModInputChange} placeholder="Enter Delivery Boy Email" />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handelModInputChange}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="modDeliveryBoyStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="modDeliveryBoyStatus" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelBoyUpdate()}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >

        )

    }
}

export default DeliveryBoys;











