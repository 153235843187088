import React from 'react'

export default function Footer() {
    return (
        <footer className="main-footer">
            <strong>Copyright © 2020 <a href="https://www.facebook.com/supremesoftwaresolutions">Supreme Software Solution</a>.</strong>
  All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.0
  </div>
        </footer>

    )
}
