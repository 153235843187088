import React, { Component } from 'react';
import axios from 'axios';
import Loading from './Loading'
import { Button, Modal } from 'react-bootstrap'




class PromoCode extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            updateShow: false,
            promoId: "1",
            promoName: "",
            promoCode: "",
            promDescription: "",
            promoType: "",
            promoDiscount: "",
            promoStatus: "",
            loading: false,
            promoData: [],

            modPromId: "",
            modPromoName: "",
            modPromoCode: "",
            modPromoDescription: "",
            modPromoType: "",
            modPromoDiscount: "",
            modPromoStatus: ""


        }
    }
    componentDidMount = () => {
        this.getPromCodeData(true);
    }

    handelModel = () => {
        this.setState({ show: !this.state.show })

    }

    handelInputChange = async (e) => {

        await this.setState({ [e.target.name]: e.target.value })

    }

    handelModInputChange = async (e) => {

        await this.setState({ [e.target.name]: e.target.value })

    }
    incrementCodeId = async () => {
        let currentId = parseInt(this.state.promoId);
        let nextId = currentId + 1;
        await this.setState({ promoId: nextId })
    }

    handelCodePost = async () => {
        let description1 = `Get Code ${this.state.promoCode} and get ${this.state.promoDiscount} % discount. Other T&C apply.	`
        let description2 = `Get Code ${this.state.promoCode} and get ${this.state.promoDiscount} Rs discount. Other T&C apply.	`

        if (this.state.promoType.value == "Percentage") {
            await this.setState({ promDescription: description1 })

        }
        else {
            await this.setState({ promDescription: description2 })

        }

        await this.postCode();
        this.setState({
            promoName: "",
            promoCode: "",
            promDescription: "",
            promoType: "",
            promoDiscount: "",
            promoStatus: "",
            show: false
        })
        this.getPromCodeData();
    }

    postCode = async () => {
        let { promoId,
            promoName,
            promoCode,
            promDescription,
            promoType,
            promoDiscount,
            promoStatus } = this.state;

        const codeDetail = {
            id: promoId,
            promoName: promoName,
            promoCode: promoCode,
            description: promDescription,
            promoType: promoType,
            discount: promoDiscount,
            status: promoStatus
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(codeDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/promoCodePost', body, config);
            this.incrementCodeId();



        } catch (err) {
            console.log(err);
        }

    }

    getPromCodeData = (loadingValue) => {

        this.setState({ loading: loadingValue })
        axios.get('https://uslaundryapi.herokuapp.com/promoCodeGet')
            .then(res => {

                this.setState({ promoData: res.data, loading: false });
                if (res.data.length > 0) {
                    const newId = res.data.map((item) => {
                        return Number(item.id)
                    })
                    let id = Math.max(...newId);
                    let finalId = id + 1;
                    this.setState({ promoId: finalId })
                }
            })
    };


    getTableData = () => {

        return (
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Promo Name</th>
                        <th>Promo Code</th>
                        <th>Description</th>
                        <th>Promo Type</th>
                        <th>Discount</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>



                    {this.state.promoData.map((data, index) => {
                        const { id, promoName, promoCode, description, promoType, discount, status } = data
                        return (
                            <tr key={index}>
                                <td>{id}</td>
                                <td>{promoName}</td>
                                <td>{promoCode}</td>
                                <td>{description}</td>
                                <td>{promoType}</td>
                                <td>{discount}</td>
                                <td ><span className={`${status === "Active" ? "badge badge-success" : "badge badge-warning"}`} style={{ padding: "7px" }}>{status}</span></td>

                                <td>
                                    <button type="button" onClick={() => this.showModification(data)} className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>
                                    <button type="button" onClick={() => this.handleDelete(data)} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table >
        )
    }

    handleDelete = (data) => {
        const codes = this.state.promoData.filter(s => s._id !== data._id);
        this.setState({ promoData: codes })

        const codeRecord = data._id;
        const promoId = {
            id: codeRecord
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify(promoId);
        axios.post('https://uslaundryapi.herokuapp.com/promoCodeDelete', body, config)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }

    showModification = async (data) => {

        await this.setState({
            modPromId: data.id,
            modPromoName: data.promoName,
            modPromoCode: data.promoCode,
            modPromoDescription: data.description,
            modPromoType: data.promoType,
            modPromoDiscount: data.discount,
            modPromoStatus: data.status,
            updateShow: true
        })

    }

    handelUpdate = async () => {

        let description1 = `Get Code ${this.state.modPromoCode} and get ${this.state.modPromoDiscount} % discount. Other T&C apply.	`
        let description2 = `Get Code ${this.state.modPromoCode} and get ${this.state.modPromoDiscount} Rs discount. Other T&C apply.	`

        if (this.state.modPromoType.value == "Percentage") {
            await this.setState({ modPromoDescription: description1 })

        }
        else {
            await this.setState({ modPromoDescription: description2 })

        }

        this.updateProduct();
        this.setState({ updateShow: false });
        this.getPromCodeData();

    }

    updateProduct = async () => {
        let { modPromId,
            modPromoName,
            modPromoCode,
            modPromoDescription,
            modPromoType,
            modPromoDiscount,
            modPromoStatus } = this.state;

        const updateCodeDetail = {
            id: modPromId,
            promoName: modPromoName,
            promoCode: modPromoCode,
            description: modPromoDescription,
            promoType: modPromoType,
            discount: modPromoDiscount,
            status: modPromoStatus
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(updateCodeDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/promoCodeUpdate', body, config);

        } catch (err) {
            console.log(err);
        }
    }

    handelModModel = () => {
        this.setState({ updateShow: false })
    }
    render() {

        return (

            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Promo Codes List</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                        {/* <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>
                                        </div> */}
                                        <button type="button" className="btn btn-success btn-md" onClick={() => this.handelModel()}><i className="fas fa-plus mr-1"></i>New</button>

                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 500 }}>
                                {this.state.loading ? <Loading /> : this.getTableData()}

                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>


                {/* new promo code */}

                <Modal show={this.state.show}>
                    <Modal.Header ><h3>Promo Codes</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.promoId}</h5>
                            </div>
                        </div>

                        <form>


                            <label htmlFor="promoCodeName">Promo Name</label>
                            <input type="text" className="form-control" id="promoCodeName" name="promoName" placeholder="Enter Promo Name" onChange={this.handelInputChange} />
                            <br />

                            <label htmlFor="promoCode">Promo Code</label>
                            <input type="text" className="form-control" id="promoCode" name="promoCode" placeholder="Enter Promo Code" onChange={this.handelInputChange} />
                            <br />
                            <label htmlFor="promoType">Select Promo Type</label>
                            <select className="form-control" id="promoType" name="promoType" onChange={this.handelInputChange}>
                                <option value="">Select Promo Type</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                            <br />
                            <label htmlFor="discount">Discount</label>
                            <input type="number" className="form-control" id="discount" name="promoDiscount" placeholder="Enter Discount Amount or Percentage" onChange={this.handelInputChange} />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handelInputChange}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="promoStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="promoStatus" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelCodePost()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* update promo code */}

                <Modal show={this.state.updateShow}>
                    <Modal.Header ><h3>Update Promo Codes</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.modPromId}</h5>
                            </div>
                        </div>

                        <form>


                            <label htmlFor="promoCodeName">Promo Name</label>
                            <input type="text" className="form-control" id="promoCodeName" name="modPromoName" value={this.state.modPromoName} placeholder="Enter Promo Name" onChange={this.handelModInputChange} />
                            <br />

                            <label htmlFor="promoCode">Promo Code</label>
                            <input type="text" className="form-control" id="promoCode" name="modPromoCode" value={this.state.modPromoCode} placeholder="Enter Promo Code" onChange={this.handelModInputChange} />
                            <br />
                            <label htmlFor="promoType">Select Promo Type</label>
                            <select className="form-control" id="promoType" name="modPromoType" onChange={this.handelModInputChange}>
                                <option value="">Select Promo Type</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                            <br />
                            <label htmlFor="discount">Discount</label>
                            <input type="number" className="form-control" id="discount" name="modPromoDiscount" value={this.state.modPromoDiscount} placeholder="Enter Discount Amount or Percentage" onChange={this.handelModInputChange} />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.handelModInputChange}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="modPromoStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="modPromoStatus" />Disable </label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelUpdate()}>Update</Button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment >

        )

    }
}

export default PromoCode;











