import React from 'react'

export default function Dashboard() {
    return (
        <React.Fragment>

            <div className="content-header">

                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Dashboard</h1>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-3 col-6">
                        {/* small box */}
                        <div className="small-box bg-info">
                            <div className="inner">
                                <h3>150</h3>
                                <p>Total Customers</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag" />
                            </div>
                            <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    {/* ./col */}
                    <div className="col-lg-3 col-6">
                        {/* small box */}
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>100</h3>
                                <p>Total Order</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars" />
                            </div>
                            <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    {/* ./col */}
                    <div className="col-lg-3 col-6">
                        {/* small box */}
                        <div className="small-box bg-warning">
                            <div className="inner">
                                <h3>12</h3>
                                <p>Completed Order</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-person-add" />
                            </div>
                            <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    {/* ./col */}
                    <div className="col-lg-3 col-6">
                        {/* small box */}
                        <div className="small-box bg-danger">
                            <div className="inner">
                                <h3>65</h3>
                                <p>Total Delivery Boys</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-pie-graph" />
                            </div>
                            <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">

                    {/* 1st graph  */}
                    <div className="col-lg-6 ">

                        <div className="card">
                            <div className="card-header border-0">
                                <div className="d-flex justify-content-between">
                                    <h3 className="card-title">Sale</h3>
                                    <a href="javascript:void(0);">View Report</a>
                                </div>
                            </div>
                            <div className="card-body">

                                {/* /.d-flex */}
                                <div className="position-relative mb-4">
                                    <canvas id="sales-chart" height={200} />
                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <span className="mr-2">
                                        <i className="fas fa-square text-primary" /> This year
                                    </span>
                                    <span>
                                        <i className="fas fa-square text-gray" /> Last year
                                    </span>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* 2nd graph */}

                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header border-0">
                                <div className="d-flex justify-content-between">
                                    <h3 className="card-title">Total Number of Orders</h3>
                                    <a href="javascript:void(0);">View Report</a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="position-relative mb-4">
                                    <canvas id="visitors-chart" height={200} />
                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <span className="mr-2">
                                        <i className="fas fa-square text-primary" /> This Week
        </span>
                                    <span>
                                        <i className="fas fa-square text-gray" /> Last Week
        </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
