import React from 'react'
import Header from './components/Header'
import Menu from './components/Menu'
import Content from './components/Content'
import Footer from './components/Footer'
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Header />
      <Router>
        <Menu />
        <Content />
      </Router>
      <Footer />
    </div>
  )
}
