import React from 'react'


export default function Customers() {
    return (

        <React.Fragment>

            <div className="row">
                <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Customer List</h3>
                            <div className="card-tools">
                                <div className="input-group input-group-sm" style={{ width: 150 }}>
                                    <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body table-responsive p-0" style={{ height: 500 }}>
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Customer Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Arslan Ahmad</td>
                                        <td>03023338991</td>
                                        <td>arslanahmadiub@gmail.com</td>
                                        <td ><span class="badge badge-success" style={{ padding: "7px" }}>Active</span></td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm mr-1 "><i class="far fa-edit"></i></button>

                                            <button type="button" class="btn btn-danger btn-sm ml-1"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>





                                </tbody>
                            </table>

                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
            </div>



        </React.Fragment >

    )
}
