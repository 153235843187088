import React, { Component } from 'react';


class Orders extends Component {
    constructor() {
        super()
        this.state = {



        }
    }



    render() {
        return (

            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Order List</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                        {/* <input type="text" name="table_search" className="form-control float-right" placeholder="Search" /> */}


                                        {/* <div className="input-group-append">
                                            <button type="submit" className="btn btn-default"><i className="fas fa-search" /></button>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 500 }}>
                                <table className="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Customer Name</th>
                                            <th>Expected Delivery Date</th>
                                            <th>Actual Delivery Date</th>
                                            <th>Collected By</th>
                                            <th>Delivered By</th>
                                            <th>Status</th>
                                            <th>View Order</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-warning" style={{ padding: "7px" }}>Order Placed</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>
                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>Arslan</td>
                                            <td>11-7-2020</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td>----</td>
                                            <td ><span className="badge badge-success" style={{ padding: "7px" }}>Delivered</span></td>
                                            <td ><span className="badge badge-primary" style={{ padding: "7px" }}>View Order</span></td>

                                            <td>
                                                <button type="button" className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>

                                                <button type="button" className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>

            </React.Fragment >

        )

    }
}

export default Orders;










