
import React, { Component } from 'react';
import axios from 'axios';
import Loading from './Loading'
import { Button, Modal } from 'react-bootstrap'




class Faqs extends Component {
    constructor() {
        super()
        this.state = {

            show: false,
            updateShow: false,
            faqId: "1",
            faqQuestion: "",
            faqAnswer: "",
            faqStatus: "",
            loading: false,

            faqsData: [],

            modFaqId: "",
            modFaqQuestion: "",
            modFaqAnswer: "",
            modFaqStatus: ""



        }
    }

    componentDidMount = () => {
        this.getFaqData();
    }

    handelModel = () => {
        this.setState({ show: !this.state.show })

    }

    changeHandler = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }

    modChangeHandler = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }
    incrementFaqId = async () => {
        let currentId = parseInt(this.state.faqId);
        let nextId = currentId + 1;
        await this.setState({ faqId: nextId })
    }

    postFaq = async () => {
        let { faqId,
            faqQuestion,
            faqAnswer,
            faqStatus } = this.state;

        const faqDetail = {
            id: faqId,
            question: faqQuestion,
            answer: faqAnswer,
            status: faqStatus
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(faqDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/faqsPost', body, config);
            this.incrementFaqId();



        } catch (err) {
            console.log(err);
        }

    }

    handelPost = async () => {
        await this.postFaq();
        await this.setState({
            faqQuestion: "",
            faqAnswer: "",
            faqStatus: "",
            show: false

        })
        this.getFaqData();
    }


    getFaqData = (loadingValue) => {

        this.setState({ loading: loadingValue })
        axios.get('https://uslaundryapi.herokuapp.com/faqsGet')
            .then(res => {

                this.setState({ faqsData: res.data, loading: false });
                if (res.data.length > 0) {
                    const newId = res.data.map((item) => {
                        return Number(item.id)
                    })
                    let id = Math.max(...newId);
                    let finalId = id + 1;
                    this.setState({ faqId: finalId })
                }
                else {
                    this.setState({ faqId: "1" })

                }
            })
    };

    getTableData = () => {

        return (
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>

                        <th>Id</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Status</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>



                    {this.state.faqsData.map((data, index) => {
                        const { id, question, answer, status } = data
                        return (
                            <tr key={index}>
                                <td>{id}</td>
                                <td>{question}</td>
                                <td>{answer}</td>
                                <td ><span className={`${status === "Active" ? "badge badge-success" : "badge badge-warning"}`} style={{ padding: "7px" }}>{status}</span></td>

                                <td>
                                    <button type="button" onClick={() => this.showModification(data)} className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>
                                    <button type="button" onClick={() => this.handleDelete(data)} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table >
        )
    }

    handleDelete = (data) => {
        const faqs = this.state.faqsData.filter(s => s._id !== data._id);
        this.setState({ faqsData: faqs })

        const faqRecord = data._id;
        const faqId = {
            id: faqRecord
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify(faqId);
        axios.post('https://uslaundryapi.herokuapp.com/faqsDelete', body, config)
            .then((res) => {
                console.log(res.data)
                this.getFaqData()
            }).catch((error) => {
                console.log(error)
            });
    }

    showModification = async (data) => {

        await this.setState({
            modFaqId: data.id,
            modFaqQuestion: data.question,
            modFaqAnswer: data.answer,
            modFaqStatus: data.status,
            updateShow: true
        })

    }

    handelModModel = () => {
        this.setState({
            updateShow: false
        })

    }

    handelUpdate = async () => {
        await this.updateFaq();
        await this.getFaqData();
        this.setState({ updateShow: false })
    }

    updateFaq = async () => {
        let { modFaqId,
            modFaqQuestion,
            modFaqAnswer,
            modFaqStatus
        } = this.state;

        const updateFaqDetail = {
            id: modFaqId,
            question: modFaqQuestion,
            answer: modFaqAnswer,
            status: modFaqStatus
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(updateFaqDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/faqsUpdate', body, config);

        } catch (err) {
            console.log(err);
        }
    }

    render() {

        return (

            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Faq List</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>

                                        <button type="button" className="btn btn-success btn-md" onClick={() => this.handelModel()}><i className="fas fa-plus mr-1"></i>New</button>

                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 500 }}>

                                {this.state.loading ? <Loading /> : this.getTableData()}

                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>

                {/* new faqs */}

                <Modal show={this.state.show}>
                    <Modal.Header ><h3>Faqs</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.faqId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="question">Question</label>
                            <textarea type="text" className="form-control" name="faqQuestion" rows="3" id="question" placeholder="Enter Question..." onChange={this.changeHandler} />
                            <br />
                            <label htmlFor="answers">Answers</label>
                            <textarea type="text" className="form-control" rows="3" name="faqAnswer" id="answers" placeholder="Enter Answers..." onChange={this.changeHandler} />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.changeHandler}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="faqStatus" value="Active" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="faqStatus" value="Disable" />Disable </label>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelPost()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* update faqs */}

                <Modal show={this.state.updateShow}>
                    <Modal.Header ><h3>Faqs</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.modFaqId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="question">Question</label>
                            <textarea type="text" className="form-control" name="modFaqQuestion" value={this.state.modFaqQuestion} rows="3" id="question" placeholder="Enter Question..." onChange={this.modChangeHandler} />
                            <br />
                            <label htmlFor="answers">Answers</label>
                            <textarea type="text" className="form-control" rows="3" name="modFaqAnswer" value={this.state.modFaqAnswer} id="answers" placeholder="Enter Answers..." onChange={this.modChangeHandler} />
                            <br />
                            <label htmlFor="status">Status</label>
                            <div onChange={this.modChangeHandler}>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="modFaqStatus" value="Active" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="modFaqStatus" value="Disable" />Disable </label>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelUpdate()}>Save</Button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment >


        )

    }
}

export default Faqs;











