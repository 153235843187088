import React, { Component } from 'react';
import axios from 'axios';
import Loading from './Loading'
import { Button, Modal } from 'react-bootstrap'




class Categories extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            categoryId: "1",
            categoryName: "",
            categoryStatus: "",
            categoryData: [],
            loading: true,

            modifyCategoryId: "",
            modifyCategoryName: "",
            modifyCategoryStatus: "",
            updateShow: false


        }
    }
    componentDidMount = () => {
        this.getCategoryData();
    }


    handelModel = () => {
        this.setState({ show: !this.state.show })

    }
    handleCategoryName = async e => {
        const categoryName = e.target.value;
        await this.setState({ categoryName: categoryName })
    }
    statusChange = async e => {
        const categoryStatus = e.target.value;
        await this.setState({ categoryStatus })

    }
    incrementCategoryId = async () => {
        let currentId = parseInt(this.state.categoryId);
        let nextId = currentId + 1;
        await this.setState({ categoryId: nextId })
    }

    postCategory = async () => {
        let { categoryId, categoryName, categoryStatus } = this.state;
        const categoryDetail = {
            id: categoryId,
            categoryName: categoryName,
            status: categoryStatus
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(categoryDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/categoryPost', body, config);
            this.incrementCategoryId();

            this.state.categoryData.push(categoryDetail)

        } catch (err) {
            console.log(err);
        }

    }


    handelCategory = async () => {
        await this.postCategory();
        await this.setState({ categoryName: "", categoryStatus: "" })
        this.setState({ show: false })
    }

    getCategoryData = () => {
        this.setState({ loading: true })
        axios.get('https://uslaundryapi.herokuapp.com/categoryGet')
            .then(res => {
                this.setState({ categoryData: res.data, loading: false });
                if (res.data.length > 0) {
                    const newId = res.data.map((item) => {
                        return Number(item.id)
                    })
                    let id = Math.max(...newId);
                    let finalId = id + 1;
                    this.setState({ categoryId: finalId })
                }

            })

    }

    getTableData = () => {

        return (
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Category Name</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>



                    {this.state.categoryData.map((data, index) => {
                        const { id, categoryName, status } = data
                        return (
                            <tr key={index}>
                                <td>{id}</td>
                                <td>{categoryName}</td>
                                <td ><span className={`${status === "Active" ? "badge badge-success" : "badge badge-warning"}`} style={{ padding: "7px" }}>{status}</span></td>
                                <td>
                                    <button type="button" onClick={() => this.showModification(data)} className="btn btn-success btn-sm mr-1 "><i className="far fa-edit"></i></button>
                                    <button type="button" onClick={() => this.handleDelete(data)} className="btn btn-danger btn-sm ml-1"><i className="fas fa-trash-alt"></i></button>
                                </td>

                            </tr>
                        )
                    })}

                </tbody>
            </table>
        )
    }

    showModification = async (data) => {
        await this.setState({ modifyCategoryId: data.id, modifyCategoryName: data.categoryName, modifyCategoryStatus: data.status, updateShow: true })

    }

    updateCategory = async () => {
        let { modifyCategoryId, modifyCategoryName, modifyCategoryStatus } = this.state;

        const updateCategoryDetail = {
            id: modifyCategoryId,
            categoryName: modifyCategoryName,
            status: modifyCategoryStatus
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const body = JSON.stringify(updateCategoryDetail);
            const res = await axios.post('https://uslaundryapi.herokuapp.com/categoryUpdate', body, config);


        } catch (err) {
            console.log(err);
        }
    }

    handleDelete = (category) => {
        const categories = this.state.categoryData.filter(s => s._id !== category._id);
        this.setState({ categoryData: categories })

        const categoryRecord = category._id;
        const categoryId = {
            id: categoryRecord
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify(categoryId);
        axios.post('https://uslaundryapi.herokuapp.com/categoryDelete', body, config)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

    }
    handleModifyCategoryName = async e => {
        const modCatName = e.target.value;
        await this.setState({ modifyCategoryName: modCatName })
    }
    modifyStatusChange = async e => {
        const modStatusChange = e.target.value;
        await this.setState({ modifyCategoryStatus: modStatusChange })
    }
    handelModifyModel = async () => {
        await this.setState({ updateShow: false })
    }
    handelModifyCategory = async () => {
        await this.updateCategory();
        await this.setState({ updateShow: false })
        this.getCategoryData();
    }

    render() {

        return (

            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12 col-12" style={{ padding: "30px" }}>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Categories List</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{ width: 150 }}>
                                        <button type="button" className="btn btn-success btn-md" onClick={() => this.handelModel()}><i className="fas fa-plus mr-1"></i>New</button>


                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0" style={{ height: 300 }}>

                                {this.state.loading ? <Loading /> : this.getTableData()}


                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>


                {/* new category model */}

                <Modal show={this.state.show}>
                    <Modal.Header ><h3>Add New Category</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.categoryId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="categoryName">Category Name</label>
                            <input type="text" className="form-control" id="categoryName" value={this.state.categoryName} onChange={this.handleCategoryName} placeholder="Enter Category Name" />
                            <br />

                            <label htmlFor="status" >Status</label>
                            <div onChange={this.statusChange}>
                                <div className="form-check-inline" >
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="categoryStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="categoryStatus" />Disable</label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelCategory()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* update category model */}

                <Modal show={this.state.updateShow}>
                    <Modal.Header ><h3>Modify Category</h3></Modal.Header>
                    <Modal.Body>
                        {/* Input Form Here */}

                        <div className="row">
                            <div className="col-md-4">
                                <h5>Id</h5>
                            </div>
                            <div className="col-md-8">
                                <h5>{this.state.modifyCategoryId}</h5>
                            </div>
                        </div>

                        <form>
                            <label htmlFor="categoryName">Category Name</label>
                            <input type="text" className="form-control" id="categoryName" value={this.state.modifyCategoryName} onChange={this.handleModifyCategoryName} placeholder="Enter Category Name" />
                            <br />

                            <label htmlFor="status" >Status</label>
                            <div onChange={this.modifyStatusChange}>
                                <div className="form-check-inline" >
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Active" name="categoryStatus" />Active</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" value="Disable" name="categoryStatus" />Disable</label>
                                </div>
                            </div>


                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={() => this.handelModifyModel()}>Close</Button>
                        <Button className="btn btn-success" onClick={() => this.handelModifyCategory()}>Update</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >

        )

    }
}

export default Categories;










