import React from 'react'
import { Switch } from "react-router-dom";
import Route from "react-router-dom/Route";
import Dashboard from './Dashboard'
import Orders from './Orders'
import Services from './Services'
import Categories from './Categories'
import Products from './Products'
import Management from './Management'
import Customers from './Customers'
import DeliveryBoys from './DeliveryBoys'
import PromoCode from './PromoCode'
import Label from './Label'
import Faqs from './Faqs'

export default function Content() {
    return (
        <div className="content-wrapper" id="content">


            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/orders" component={Orders} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/categories" component={Categories} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/management" component={Management} />
                <Route exact path="/customers" component={Customers} />
                <Route exact path="/deliveryBoys" component={DeliveryBoys} />
                <Route exact path="/promoCodes" component={PromoCode} />
                <Route exact path="/labels" component={Label} />
                <Route exact path="/faqs" component={Faqs} />


            </Switch>



            {/* <Dashboard />
            <Orders />
            <Services />
            <Categories />
            <Products />
            <Management />
            <Customers />
            <DeliveryBoys />
            <PromoCode />
            <Label />
            <Faqs /> */}

        </div>
    )
}
